<template>
  <div>
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ myTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Heading heading="h4" :content="myTitle"></Heading>
    <small> View {{ myTitle }} </small>
    <hr />
    <div class="text-center mt-3 mb-3">
      <el-pagination
        background
        @current-change="setPage"
        :page-size="pageSize"
        :current-page="page"
        :total="resultsTotal"
        :pager-count="10"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
    <el-table class="mt-4" :data="teachers" style="min-width: 100%" border>
      <el-table-column label="Name">
        <template slot-scope="scope">
          <template>
            <b>
              {{ user.displayName(scope.row.first_name, scope.row.last_name) }}
            </b>
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="email" label="Email" width="350">
        <template slot-scope="scope">
          <b v-if="scope.row.email_verified_at" class="text-success">
            <el-tooltip
              class="item"
              effect="dark"
              :content="
                `${instant.formatGoogleTime(
                  scope.row.email_verified_at
                )} verified`
              "
              placement="top"
            >
              <span>
                <i class="el-icon-success"></i>
                {{ scope.row.email }}
              </span>
            </el-tooltip>
          </b>
          <b v-else class="text-warning">
            <el-tooltip
              class="item"
              effect="dark"
              content="Pending"
              placement="top"
            >
              <span>
                <i class="el-icon-warning"></i>
                {{ scope.row.email }}
              </span>
            </el-tooltip>
          </b>
        </template>
      </el-table-column>

      <el-table-column label="Balance" width="140">
        <template slot-scope="scope">
          <h5 class="text-success">
            {{ scope.row.coin }}
            <small v-if="scope.row.coin > 1">Coins</small>
            <small v-else-if="scope.row.coin > 0 && scope.row.coin < 2">
              Coin
            </small>
            <el-button
              class="ml-2"
              type="text"
              @click="selectStudent(scope.row)"
            >
              <i class="fa fa-edit"></i>
            </el-button>
          </h5>
        </template>
      </el-table-column>
      <el-table-column label="Plan" width="80">
        <template slot-scope="scope">
          <b
            v-if="(scope.row.all_roles || []).includes('pro')"
            style="color: orange;"
          >
            <span class="proIcon">
              <i class="fas fa-crown" />
            </span>
            Pro
          </b>
          <b v-else>
            <el-tooltip
              class="item"
              effect="dark"
              content="Click to Upgrade 12 MONTHS Pro"
              placement="top"
            >
              <el-button
                type="info"
                @click="assignYearlyPro(scope.row.id)"
                size="small"
              >
                Free
              </el-button>
            </el-tooltip>
          </b>
        </template>
      </el-table-column>
      <el-table-column label="Date Registered" width="150">
        <template slot-scope="scope">
          {{ instant.formatGoogleTime(scope.row.created_at) }}
        </template>
      </el-table-column>
      <el-table-column label="IP" width="200">
        <template slot-scope="scope">
          <span v-if="scope.row.last_ip">
            <template v-if="scope.row.last_iso">
              ({{ scope.row.last_iso }})
            </template>
            {{ scope.row.last_ip }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Logs" width="80">
        <template slot-scope="scope">
          <router-link
            :to="{
              name: 'AdminUsersLogs',
              query: {
                search: scope.row.email
              }
            }"
          >
            <b>
              <i class="fa fa-eye"></i>
            </b>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
    <div class="text-center mt-3">
      <el-pagination
        background
        @current-change="setPage"
        :page-size="pageSize"
        :current-page="page"
        :total="resultsTotal"
        :pager-count="10"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="Create New Company"
      :visible.sync="showCompanyModal"
      width="50%"
    >
      <el-form label-width="100px">
        <el-form-item label="Name">
          <el-input v-model="newCompany.name"></el-input>
        </el-form-item>
        <el-form-item label="User">
          <el-select
            style="width: 100%;"
            v-model="newCompany.user_id"
            filterable
            remote
            reserve-keyword
            placeholder="Email / Name"
            :remote-method="searchTeachers"
          >
            <el-option
              v-for="(teacher, index) in teachers"
              :key="teacher.id"
              :label="
                `${user.displayName(teacher.first_name, teacher.last_name)} [${
                  teacher.email
                }]`
              "
              :value="teacher.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Introduction">
          <el-input type="textarea" :rows="3" v-model="newCompany.introduction">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showCompanyModal = false">Cancel</el-button>
        <el-button type="success" @click="crateNewCompany">Create</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import Company from "@/apis/company";
import SAT from "@/apis/sat";
import userApi from "@/apis/user";
import { user, instant } from "@ivy-way/material";

export default {
  metaInfo() {
    return {
      title: `Company ${this.myTitle} - ${this.CompanyName}`
    };
  },

  components: {
    Breadcrumb
  },

  mixins: [],

  props: [],
  data() {
    return {
      newCompany: {
        name: null,
        user_id: null,
        introduction: null
      },
      teachers: [],
      companies: null,
      showCompanyModal: false,
      resultsTotal: null,
      showRole: false,
      pageSize: 10
    };
  },
  computed: {
    ...mapState("user", ["token", "lang"]),
    ...mapGetters("user", ["token", "getProfile", "getIsPro"]),
    myTitle() {
      return "Teachers";
    },
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    companyId() {
      return this.getProfile.company_id;
    },
    page() {
      let page = 1;
      if (this.$route.query.page === undefined) {
        page = 1;
      } else {
        page = this.$route.query.page;
      }
      return parseInt(page);
    }
  },
  watch: {
    companyId() {
      this.getTeachers();
    }
  },

  mounted() {
    if (this.$route.query.search) {
      this.studentQuery = this.$route.query.search;
    }
    // this.searchTeachers();
    this.getTeachers();
  },

  methods: {
    async crateNewCompany() {
      await Company.createCompany(this.newCompany);
      this.getCompany();
      this.showCompanyModal = false;
    },
    async searchTeachers(query) {
      const students = await SAT.getStudents({
        keyword: query !== "" ? query : null,
        per_page: 50,
        page: this.page,
        sort: "name",
        roles: "teacher"
      });
      this.teachers = students.users.data;
    },
    searchUsers() {
      this.routerPush({
        params: { ...this.$route.params },
        query: {
          ...this.$route.query,
          page: 1,
          search: this.studentQuery !== "" ? this.studentQuery : null
        }
      });
    },
    setPage(page) {
      this.routerPush({
        params: { ...this.$route.params },
        query: { ...this.$route.query, page: page }
      });
    },
    async getTeachers() {
      // let query = this.studentQuery;
      if (this.companyId) {
        this.loading = true;
        const res = await Company.listCompanyTeachers(this.companyId, {
          page: this.page
        });
        this.loading = false;
        this.teachers = res.users.data;
        this.resultsTotal = res.companies.total;
        this.pageSize = res.companies.per_page;
      }
    },
    selectStudent(student) {
      this.student = null;
      this.student = student;
      this.showCompanyModal = true;
      this.form.uid = student.id;
      this.form.oCoins = student.coin;
      this.form.all_roles = student.all_roles;
    },
    selectRole(student) {
      this.student = null;
      this.student = student;
      this.showRole = true;
      this.form.uid = student.id;
      this.form.oCoins = student.coin;
      this.form.all_roles = student.all_roles;
    },
    async saveRoles() {
      const res = await userApi.saveRoles(this.form.uid, {
        roles: this.form.all_roles
      });
      this.$message({
        message: "Success!",
        type: "success"
      });
      // window.location.reload();
      this.getStudents();
      this.showRole = false;
      this.form = {
        uid: null,
        type: 1,
        coins: 0,
        oCoins: 0,
        all_roles: []
      };
    },
    async giveCoins() {
      if (this.form.type === 1) {
        const res = await SAT.giveCoins(this.form.uid, {
          coins: this.form.coins
        });
        this.$message({
          message: "Success!",
          type: "success"
        });
      } else {
        const res = await SAT.deductCoins(this.form.uid, {
          coins: this.form.coins
        });
        this.$message({
          message: "Success!",
          type: "success"
        });
      }
      this.getStudents();
      this.showCompanyModal = false;
      this.form = {
        uid: null,
        type: 1,
        coins: 0,
        oCoins: 0,
        all_roles: []
      };
    }
  }
};
</script>

<style scoped></style>
